/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.3/dist/jquery.min.js
 * - /npm/@barba/core@2.9.7/dist/barba.umd.min.js
 * - /npm/locomotive-scroll@4.1.4/dist/locomotive-scroll.min.js
 * - /npm/@splidejs/splide@4.1.4/dist/js/splide.min.js
 * - /npm/@splidejs/splide-extension-auto-scroll@0.5.3/dist/js/splide-extension-auto-scroll.min.js
 * - /npm/gsap@3.11.4/dist/gsap.min.js
 * - /npm/gsap@3.11.4/dist/Flip.min.js
 * - /npm/imagesloaded@5.0.0/imagesloaded.pkgd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
